<template>
  <div class="layout-container">
    <div class="index-container">
      <div>
        <div class="header">
          <img
            alt="Vue logo"
            src="../assets/virus-banner.361329c1.jpg"
            style="width: 100%"
          />
        </div>
        <div class="content">
          <van-form
            :show-error="false"
            :input-align="'right'"
            :error-message-align="'right'"
            @submit="onSubmit"
          >
            <div class="form">
              <van-field
                v-model="username"
                name="username"
                label="姓名："
                placeholder="请输入姓名"
                :rules="[{ required: true, message: '姓名必填' }]"
              />
              <van-field
                v-model="idcode"
                name="idcode"
                label="证件号："
                placeholder="请输入证件号"
                :rules="[{ required: true, message: '证件号必填' }]"
              />
            </div>
            <div class="submit-btn">
              <van-button round block type="info" native-type="submit"
                >查询</van-button
              >
            </div>
          </van-form>
          <div class="notice">
            <h3 class="title">温馨提示 <span class="h1-border"></span></h3>
            <p class="item">1、支持查询最近5次核酸检测结果；</p>
            <p class="item">
              2、支付宝、微信每日各可进行10次查询（包含扫码）；
            </p>
            <p class="item">
              3、核酸检测结果仅用于健康码亮码或出行参考，违规查询他人信息保留追究责任。
            </p>
          </div>
        </div>
      </div>
      <div class="logo">
        <img src="../assets/ic_logo.db4c7908.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      idcode: "",
    };
  },
  methods: {
    onSubmit(val) {
        console.log('aaaaa', val);
        this.$router.push({ path: '/result', query: { ...val }})
    }
  }
};
</script>

<style lang="less" scoped>
.layout-container {
  height: 100%;
  width: 100%;
}
.index-container {
  min-height: 100%;
  width: 100%;
  font-family: Microsoft Yahei;
  background-color: #f8f8f8;
  padding-bottom: 0.53333rem;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  box-sizing: border-box;
  .header {
    min-height: 2.66667rem;
  }
  .content {
    width: 100%;
    padding: 0 0.4rem;
    background-color: transparent;
    margin-top: -0.85333rem;
    padding-bottom: 1.06667rem;
    .form {
      border-radius: 0.26667rem;
      overflow: hidden;
    }
    .submit-btn {
      margin: 1.33333rem auto;
      width: 6.13333rem;
    }
    .notice {
      border-radius: 0.21333rem;
      background-color: #fff;
      color: #101010;
      font-size: 0.37333rem;
      box-shadow: 0 0.05333rem 0.16rem 0 #ededed;
      font-family: -apple-system;
      border: 0.02667rem solid #fff;
      padding: 0.26667rem 0.4rem 0.26667rem;
      margin-bottom: 0.64rem;
      .title {
        font-weight: 600;
        color: #222;
        font-size: 0.42667rem;
        text-align: left;
        margin: 0;
        margin-bottom: 0.16rem;
        position: relative;
        .h1-border {
          position: absolute;
          width: 1.81333rem;
          height: 0.10667rem;
          background: #ffde39;
          left: 0;
          bottom: 0;
          opacity: 0.8;
          border-radius: 0.13333rem 0.13333rem 0 0;
        }
      }
      .item {
        font-weight: 400;
        color: #555;
        font-size: 0.37333rem;
        text-align: left;
        margin-top: 0.10667rem;
        line-height: 1.5;
      }
    }
  }
  .logo {
    margin: 0 auto 0.53333rem;
    width: 1.86667rem;
    height: 50%;
    pointer-events: none;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
</style>
